import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24')
];

export const server_loads = [4,3,6];

export const dictionary = {
		"/[[lang=lang]]/(frontend)/calendar/[[start=date]]/[[stop=date]]/[[userId]]": [~14,[2,4],[,5]],
		"/[[lang=lang]]/(frontend)/dashboard": [~15,[2,4],[,5]],
		"/[[lang=lang]]/(frontend)/email-previews/[[model]]": [~16,[2,4],[,5]],
		"/[[lang=lang]]/(auth)/login": [8,[2,3]],
		"/[[lang=lang]]/(auth)/password-reset": [9,[2,3]],
		"/[[lang=lang]]/(auth)/password-reset/[token]": [~10,[2,3]],
		"/[[lang=lang]]/(frontend)/schedule/[[start=date]]/[[stop=date]]/[[shiftId]]": [~17,[2,4],[,5]],
		"/[[lang=lang]]/(frontend)/settings": [18,[2,4,6],[,5]],
		"/[[lang=lang]]/(frontend)/settings/(settings)/company": [~19,[2,4,6,7],[,5]],
		"/[[lang=lang]]/(frontend)/settings/(settings)/invoices": [~20,[2,4,6,7],[,5]],
		"/[[lang=lang]]/(frontend)/settings/(settings)/terminals": [~21,[2,4,6,7],[,5]],
		"/[[lang=lang]]/(frontend)/settings/(settings)/wages": [~22,[2,4,6,7],[,5]],
		"/[[lang=lang]]/(frontend)/test": [~23,[2,4],[,5]],
		"/[[lang=lang]]/(frontend)/users/[[companyUserId]]/[[action=action]]": [~24,[2,4],[,5]],
		"/[[lang=lang]]/(auth)/welcome": [~11,[2,3]],
		"/[[lang=lang]]/(auth)/welcome/[companyUserId]": [~12,[2,3]],
		"/[[lang=lang]]/(frontend)": [~13,[2,4],[,5]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';